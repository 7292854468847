var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"title font-weight-bold"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-text-field',{staticClass:"search",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticStyle:{"margin-right":"5px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.exportItems()}}},[_vm._v(" Print ")]),_c('v-dialog',{attrs:{"max-width":"750px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("Add")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"title font-weight-bold"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._t("formFields")],2)],1),_c('v-card-actions',{staticClass:"grey lighten-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveItem}},[_vm._v("Save")])],1)],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"search":_vm.search,"loading":_vm.loading,"show-expand":_vm.expandable,"item-key":_vm.itemKey},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._t("expandContent",null,{"item":item})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }